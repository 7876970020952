<template>
    <InvitationCapture @clicked="updateStep" :step="step"/>
</template>

<script>
// @ is an alias to /src
import InvitationCapture from "@/components/InvitationCapture.vue";

export default {
  name: "Invitation",
  components: {
    InvitationCapture,
  },
  data() {
    return {
      step: 0,
      request: {
        requestUUID: null,
        emailAddress: null,
        emailVerified: false,
        emailUUID: null,
        fullName: null,
        oldAddress: null,
        newAddress: null,
        cc: false,
        organisations: [],
      }
    }
  },
  methods: {
    updateStep (value) {
      this.step += value;
      this.$router.push('email')
    },
  }
};
</script>
<style scoped>
.home {
  margin: 5vh auto;
  border-radius: 50px;
  height: 100%;
}
</style>
