<template>
    <v-container fill-height>
      <v-row
      v-if="this.isPending"
      align="center"
      justify="space-around"
      no-gutters>
        <v-col
          cols="12"
          sm="4"
        >
        <h2>Invitation only</h2>
        <p>
          We're still starting out, which means we've had to limit the number of users.
        </p>
        <p class="mt-12 mb-1"><b>I have an invite</b></p>
        <p>
          Put our email in the box to get started.
        </p>
        <p class="mt-12 mb-1"><b>I want an invite</b></p>
        <p>
          Put in your email and we'll let you know as soon as a spot opens up.
        </p>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
        <div class="emailInput">
            <br>
            <v-text-field
              label="Email"
              outlined
              v-model="invitationEmail"
              :rules="[rules.email]"
              class="emailInputField mt-4"
              @keyup.enter="checkWaitlistStatus"
            ></v-text-field>
            <v-btn
              dark
              @click="checkWaitlistStatus"
              class="mt-0"
            >
              Go
            </v-btn>
        </div>
        </v-col>
      </v-row>
      <v-row
      v-if="this.isApproved"
      align="center"
      justify="space-around"
      no-gutters>
        <v-col
            cols="12"
            class="text-center"
          >
          <h2>You're in!</h2>
          <p class="mt-12 mb-1">We're ready and excited for you to try "Nothing Left Behind"</p>
          <p class="mt-12 mb-1">
            Please bear in mind though that it's still under development, so stuff might break. If it does,
            or you have any other feedback, please get in touch: contact@nothingleftbehind.co.uk
          </p>
          <v-btn
              dark
              @click="onClickButtonNext"
              class="mt-12"
            >
              Start
            </v-btn>
        </v-col>
      </v-row>
      <v-row
      v-if="this.isWaitlisted"
      align="center"
      justify="space-around"
      no-gutters>
        <v-col
            cols="12"
            class="text-center"
          >
          <h2>We've added you to the waitlist</h2>
          <p class="mt-12 mb-1">Once we're ready for you to try our service, we'll send you an email.</p>
          <p class="mt-12 mb-1">
            In the meantime, feel free to get in touch if you have any questions: contact@nothingleftbehind.co.uk
          </p>
        </v-col>
      </v-row>
      
    </v-container>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";
import store from '@/store';

export default {
  name: "Welcome",
  components: {
    // NavBar,
  },
  data() {
    return {
      invitationEmail: null,
      status: 'PENDING',
      rules: {
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
      },
    }
  },
  props: {
    step: Number,
  },
  methods: {
    onClickButtonNext () {
      this.$emit('clicked', 1)
    },
    onClickButtonPrevious () {
      this.$emit('clicked', -1)
    },
    onGoToStep (value) {
      this.$emit('goToStep', value)
    },
    checkWaitlistStatus () {
      // Call API to check
      // Get unique token
      if (this.invitationEmail === null) {
        return
      }
      fetch(`${process.env.VUE_APP_API_URL}\\${process.env.VUE_APP_API_STAGE}\\waitlist?emailAddress=${this.invitationEmail}`)
        .then(response => response.json())
        .then(data => {
          if('approved' in data && data.approved){
            this.status = 'APPROVED'
            store.commit('setInvitationVerified', true);
          } else {
            this.status = 'WAITLISTED'
          }
        })
        .catch(err => console.log(err));
    }
  },
  computed: {
    isPending(){
      return this.status === 'PENDING';
    },
    isApproved(){
      return this.status === 'APPROVED';
    },
    isWaitlisted(){
      return this.status === 'WAITLISTED';
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
h3 {
  margin: 40px 0 0;
}

h1 {
  font-family: 'Abril Fatface', cursive !important;
  font-weight: normal !important;
  font-size: 5em;
}

.centralText {
  max-width: 50%;
  margin: auto;
}

.text-center {
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.stepBox img {
  margin-top: 20px;
}
</style>
