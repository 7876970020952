<template>
    <InvitationCapture @clicked="updateStep" @goToStep="customStep" v-if="step == 0" :step="step"/>
    <YourEmail @clicked="updateStep" @goToStep="customStep" @emailAddress="setEmailAddress" @requestUUID="setRequestUUID" v-else-if="step == 1" :step="step"/>
    <EmailVerified @clicked="updateStep" @goToStep="customStep" @emailAddress="setEmailVerification" @emailUUID="setEmailUUID" v-else-if="step == 2" :step="step"/>
    <YourDetails @clicked="updateStep" @goToStep="customStep" @fullName="setFullName" @oldAddress="setOldAddress" @newAddress="setNewAddress" @cc="setCC" v-else-if="step == 3" :step="step"/>
    <Organizations @clicked="updateStep" @goToStep="customStep" @organisations="setOrganisations" v-else-if="step == 4" :step="step"/>
    <ConfirmationStep @clicked="updateStep" @goToStep="customStep" :request="request" v-else-if="step == 5" :step="step"/>
    <EndOfFlow @clicked="updateStep" @goToStep="customStep" v-else-if="step == 6" :step="step"/>
    <!-- {{request}} -->
</template>

<script>
// @ is an alias to /src
import InvitationCapture from "@/components/InvitationCapture.vue";
import YourEmail from "@/components/YourEmail.vue";
import EmailVerified from "@/components/EmailVerified.vue";
import YourDetails from "@/components/YourDetails.vue";
import Organizations from "@/components/Organizations.vue";
import ConfirmationStep from "@/components/ConfirmationStep.vue";
import EndOfFlow from "@/components/EndOfFlow.vue";

export default {
  name: "ProcessPages",
  components: {
    InvitationCapture,
    YourEmail,
    EmailVerified,
    YourDetails,
    Organizations,
    ConfirmationStep,
    EndOfFlow,
  },
  data() {
    return {
      step: 0,
      request: {
        requestUUID: null,
        emailAddress: null,
        emailVerified: false,
        emailUUID: null,
        fullName: null,
        oldAddress: null,
        newAddress: null,
        cc: false,
        organisations: [],
      }
    }
  },
  methods: {
    updateStep (value) {
      this.step += value;
    },
    customStep (value) {
      this.step = value;
    },
    setRequestUUID (value) {
      this.request['requestUUID'] = value;
    },
    setEmailUUID (value) {
      this.request['emailUUID'] = value;
    },
    setEmailAddress (value) {
      this.request['emailAddress'] = value;
    },
    setEmailVerification () {
      this.request['emailVerified'] = true;
    },
    setFullName (value) {
      this.request['fullName'] = value;
    },
    setOldAddress (value) {
      this.request['oldAddress'] = value;
    },
    setNewAddress (value) {
      this.request['newAddress'] = value;
    },
    setCC (value) {
      this.request['cc'] = value;
    },
    setOrganisations (value) {
      this.request['organisations'] = value;
    },
  }
};
</script>
<style scoped>
.home {
  margin: 5vh auto;
  border-radius: 50px;
  height: 100%;
}
</style>
