<template>
    <v-container fill-height>
      <!-- <v-row 
      align="end"
      no-gutters>
      <v-col
          sm="12"
      >
        <h3>Nothing<br>Left<br>Behind</h3>
      </v-col>
      </v-row> -->
      <v-row 
      align="center"
      justify="center"
      no-gutters>
        <v-col
          sm="12"
        >
          <div class="text-center">
            <h2>Verified
            <v-icon
              large
              color="green darken-2"
            >
              mdi-check
    </v-icon>
            </h2>
            <p class="centralText">
              We're good to go
            </p>
            <v-btn
                dark
                @click="onClickButtonNext"
              >
                Proceed
              </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";

export default {
  name: "YourEmail",
  components: {
    // NavBar,
  },
  props: {
    step: Number,
  },
  methods: {
    onClickButtonNext () {
      this.$emit('clicked', 1)
    },
    onGoToStep (value) {
      this.$emit('goToStep', value)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.nextBtn {
  width: 25%;
  height: 50px;
  background-color: #0B80D4;
  margin: 20px auto;
}

/* .nextBtn p {
  margin: 1px auto;
} */
</style>
