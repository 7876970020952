<template>
    <!-- <div class="container"> -->
      <!-- <NavBar @goToStep="onGoToStep" :step="step"/> -->
      <v-container fill-height>
      <!-- <v-row 
      align="end"
      no-gutters>
      <v-col
          sm="12"
      >
        <h3>Nothing<br>Left<br>Behind</h3>
      </v-col>
      </v-row> -->
      <!-- <div class="stepBox"> -->
        <!-- <h1>Change My Address</h1> -->
        <!-- <div> -->
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 24 24" class="successIcon"> -->
            <!-- <path d="M21.856 10.303c.086.554.144 1.118.144 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11

              4.925-11 11-11c2.347 0 4.518.741 6.304 1.993l-1.422 1.457c-1.408-.913-3.082-1.45-4.882-1.45-4.962
              0-9 4.038-9 9s4.038 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.866-1.902zm-.952-8.136l-9.404
              9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z" style="fill:#0B80D4;"/> -->
          <!-- </svg> -->
      <v-row 
      align="end"
      no-gutters>
      <v-col
          sm="12"
      >   
        <div class="centralText">
          <h2>Success!</h2>
          <v-icon
              x-large
              color="green darken-2"
            >
              mdi-check
          </v-icon>
          <br>
          <p class="centralText">
            Your requests have been sent.
          </p>
          <!-- <br> -->
          <!-- <p class="centralText">
            None of your details or data has been stored by us.
          </p> -->
          <!-- <br>
          <hr>
          <br> -->
          <!-- <p class="centralText">
            Organisations may in touch with you directly if they need to verify anything or require further information (e.g. membership number).
          </p> -->
          </div>
                </v-col>
      </v-row>
      <v-row>
        <v-col
        sm="12">
        <div class="text-center">
            <v-btn
                dark
                @click="goToHome()"
                class="mx-2"
              >
                close
              </v-btn>
          </div>
        </v-col>
      </v-row>
      </v-container>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";

export default {
  name: "EndOfFlow",
  components: {
    // NavBar,
  },
  props: {
    step: Number,
  },
  methods: {
    goToHome () {
      this.$emit('goToHome')
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.successIcon {
  margin: 40px 0 0;
}

.centralText {
  /* max-width: 50%; */
  /* margin: auto; */
  text-align: center;
}
</style>
