<template>
    <ConfirmationStep @clicked="updateStep" @goToStep="customStep" :request="request" :step="step"/>
</template>

<script>
// @ is an alias to /src
import ConfirmationStep from "@/components/ConfirmationStep.vue";
import store from '@/store';

export default {
  name: "ConfirmationStepView",
  components: {
    ConfirmationStep,
  },
  data() {
    return {
      step: 0,
      request: store.getters.request,
    }
  },
  methods: {
    updateStep (value) {
      this.step += value;
      if(value == 1){
        this.$router.push('end');
      } else {
        this.$router.push('orgs')
      }
    },
    customStep (value) {
      this.step = value;
    },
    setRequestUUID (value) {
      this.request['requestUUID'] = value;
    },
    setEmailUUID (value) {
      this.request['emailUUID'] = value;
    },
    setEmailAddress (value) {
      this.request['emailAddress'] = value;
    },
    setEmailVerification () {
      this.request['emailVerified'] = true;
    },
    setFullName (value) {
      this.request['fullName'] = value;
    },
    setOldAddress (value) {
      this.request['oldAddress'] = value;
    },
    setNewAddress (value) {
      this.request['newAddress'] = value;
    },
    setCC (value) {
      this.request['cc'] = value;
    },
    setOrganisations (value) {
      this.request['organisations'] = value;
    },
  }
};
</script>
<style scoped>
.home {
  margin: 5vh auto;
  border-radius: 50px;
  height: 100%;
}
</style>
