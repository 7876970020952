<template>
    <YourDetails @clicked="updateStep" @goToStep="customStep" @fullName="setFullName" @oldAddress="setOldAddress" @newAddress="setNewAddress" @cc="setCC" @dob="setDob" :step="step"/>
</template>

<script>
// @ is an alias to /src
import YourDetails from "@/components/YourDetails.vue";
import store from '@/store'

export default {
  name: "CollectDetails",
  components: {
    YourDetails,
  },
  data() {
    return {
      step: 0,
      request: {
        requestUUID: null,
        emailAddress: null,
        emailVerified: false,
        emailUUID: null,
        fullName: null,
        oldAddress: null,
        newAddress: null,
        cc: false,
        organisations: [],
        dob: null,
      }
    }
  },
  methods: {
    updateStep (value) {
      this.step += value;
      if(value == 1){
        this.$router.push('orgs');
      }
      
    },
    customStep (value) {
      this.step = value;
    },
    setFullName (value) {
      this.request['fullName'] = value;
      store.commit('setFullName', value);
    },
    setOldAddress (value) {
      this.request['oldAddress'] = value;
      store.commit('setOldAddress', value);
    },
    setNewAddress (value) {
      this.request['newAddress'] = value;
      store.commit('setNewAddress', value);
    },
    setCC (value) {
      this.request['cc'] = value;
      store.commit('setCC', value);
    },
    setDob (value) {
      this.request['dob'] = value;
      store.commit('setDob', value);
    }
  }
};
</script>
<style scoped>
.home {
  margin: 5vh auto;
  border-radius: 50px;
  height: 100%;
}
</style>
