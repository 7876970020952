<template>
    <v-container fill-height>
      <!-- <v-row 
      align="end"
      no-gutters>
      <v-col
          sm="12"
      >
        <h3>Nothing<br>Left<br>Behind</h3>
      </v-col>
      </v-row> -->
      <v-row 
      align="center"
      justify="center"
      no-gutters>
        <v-col
          sm="12"
        >
        <div>
          <h2>Your details</h2>
          <div class="form-holder">
            <v-text-field
              label="Full name"
              outlined
              v-model="fullName"
              class="mt-4"
              :error="isEmptyAfterClick(fullName)"
            ></v-text-field>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="vmenu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date of Birth"
                  readonly
                  outlined
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :error="isEmptyAfterClick(date)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :active-picker.sync="activePicker"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="1903-01-01"
                @change="save"
              ></v-date-picker>
            </v-menu>
            <v-textarea
              v-for="i in numberOfPreviousAddresses"
              :key="i"
              :label="previousAddressLabel(i, numberOfPreviousAddresses)"
              outlined
              v-model="oldAddress[i-1]"
              class="mt-4"
              :hint="hintText(i, numberOfPreviousAddresses)"
              persistent-hint
              :error="isEmptyAfterClick(oldAddress[i-1])"
            ></v-textarea>
            <div>
            <div class="countButtons">
              <v-btn
                v-if="numberOfPreviousAddresses > 1"
                color="error"
                x-small
                @click="changePreviousAddressCount(-1)"
                class="mx-2"
              >
                -
              </v-btn>
              <v-btn
                x-small
                color="success"
                @click="changePreviousAddressCount(1)"
                class="mx-2"
              >
                +
              </v-btn>
            </div>
            <!-- <p class="previousAddressText">You can add more than one previous address if you're not sure which the company has stored.</p> -->
            </div>
            <v-textarea
              label="New address"
              outlined
              v-model="newAddress"
              class="mt-4"
              :error="isEmptyAfterClick(newAddress)"
            ></v-textarea>
          </div>
          <div class="text-center">
            <!-- <v-btn
                color="secondary"
                @click="onClickButtonPrevious"
                class="mx-2"
              >
                Back
              </v-btn> -->
            <v-btn
                dark
                @click="onClickButtonNext"
                class="mx-2"
              >
                Proceed
              </v-btn>
          </div>
        </div>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";
import store from '@/store'

export default {
  name: "YourEmail",
  components: {
    // NavBar,
  },
  props: {
    step: Number,
  },
  methods: {
    onClickButtonNext () {
      if (!this.fieldMissing()) {
        this.$emit('fullName', this.fullName)
        this.$emit('oldAddress', this.oldAddress)
        this.$emit('newAddress', this.newAddress)
        this.$emit('cc', this.cc)
        this.$emit('dob', this.date)
        this.$emit('clicked', 1)
      }
    },
    onClickButtonPrevious () {
      this.$emit('clicked', -1)
    },
    onGoToStep (value) {
      this.$emit('goToStep', value)
    },
    save (date) {
        this.$refs.menu.save(date)
    },
    changePreviousAddressCount(change){
      this.numberOfPreviousAddresses = this.numberOfPreviousAddresses + change;
    },
    hintText (i, total) {
        if(i === total){
          return "You can add more than one previous address if you're not sure which the company has stored."
        }
        return ''
      },
    previousAddressLabel(i, total) {
      if(total === 1){
        return "Previous Address"
      }
      return `Previous Address ${i}`
    },
    getFullName(){
      return store.getter.fullName;
    },
    fieldMissing() {
      if (!this.fullName) {
        this.clickFailed = true;
        return true
      }
      if (!this.date) {
        this.clickFailed = true;
        return true
      }
      if (!this.newAddress) {
        this.clickFailed = true;
        return true
      }
      if (!this.oldAddress) {
        this.clickFailed = true;
        return true
      }
      return false
    },
    isEmptyAfterClick(model){
      if (this.clickFailed && !model) {
        return true;
      }
      return false;
    }
  },
  data() {
    return {
      numberOfPreviousAddresses: 1,
      fullName: store.getters.fullName,
      oldAddress: store.getters.oldAddress,
      newAddress: store.getters.newAddress,
      cc: true,
      activePicker: null,
      date: store.getters.dob,
      menu: false,
      clickFailed: false,
    }
  },
  watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
  computed: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  margin-left: 0 !important;
  max-width: 100% !important;
}

.centralText {
  max-width: 50%;
  margin: auto;
}

.vmenu {
  width: 100% !important;
}

.countButtons {
  text-align: right;
}

.previousAddressText {
  font-size: 0.8em;
}

.form-holder {
  width: 60%;
  text-align:left;
  margin: auto;
}

@media (max-width: 600px) {
  .form-holder {
    width: 90%;
    text-align:left;
    margin: auto;
  }
}
</style>
