<template>
    <v-container fill-height>
      <v-row 
      align="center"
      justify="center"
      no-gutters>
        <v-col
          sm="12"
        >
        <h2>We don't use accounts because we don't store your data</h2>
        <div v-if="input" class="emailInput">
            <p class="centralText mb-8">
              All we need is to verify you own your email
            </p>
            <br>
            <v-text-field
              label="Email"
              outlined
              v-model="emailAddress"
              :rules="[rules.email]"
              hint="Use multiple emails? You'll need to do this process once for each email"
              class="emailInputField mt-4"
              @keyup.enter="sendMeLinkButton"
            ></v-text-field>
            <v-btn
              dark
              @click="sendMeLinkButton"
              class="mt-0"
            >
              Send me the verification link
            </v-btn>
        </div>
        <div v-else class="emailVerify">
          <p class="centralText mb-8">
            Check the inbox for <span class="email">{{emailAddress}}</span> a verifcation code
          </p>
          <br>
          <v-text-field
              label="Verification code"
              outlined
              v-model="verificationCode"
              class="verifyInput mt-4"
              @keyup.enter="verifyEmailButton"
            ></v-text-field>
            <v-btn
              dark
              @click="verifyEmailButton"
              class="mt-0"
            >
              Verify
            </v-btn>
        </div>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";

export default {
  name: "YourEmail",
  components: {
    // NavBar,
  },
  props: {
    step: Number,
  },
  data() {
    return {
      emailAddress: "",
      input: true,
      verificationCode: "",
      rules: {
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
      },
    }
  },
  methods: {
    sendMeLinkButton () {
      this.$emit('emailAddress', this.emailAddress);
      this.input = false;
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ emailAddress: this.emailAddress})
      };
      // Send verification code
      fetch(`${process.env.VUE_APP_API_URL}\\${process.env.VUE_APP_API_STAGE}\\email`, requestOptions)
        .catch(err => (console.log(err)));
    },
    verifyEmailButton () {
      // Call API to check
      // Get unique token
      fetch(`${process.env.VUE_APP_API_URL}\\${process.env.VUE_APP_API_STAGE}\\email?emailAddress=${this.emailAddress}&verificationCode=${this.verificationCode}`)
        .then(response => response.json())
        .then(data => {
          if('validated' in data && data.validated){
            this.$emit('setEmailVerification')
          }
        })
        .catch(err => console.log(err));
    },
    onGoToStep (value) {
      this.$emit('goToStep', value)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.centralText {
  max-width: 50%;
  margin: auto;
  margin-bottom: 20px;
}

.email {
  color: #153B2A;
  font-weight: bold;
}

.emailInput {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}

.emailVerify {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}

.emailInputField {
  width: 25%;
  min-width: 500px;
  margin: auto !important;
}

@media (max-width: 600px) {
  .emailInputField {
    width: 90%;
    min-width: 0;
    margin: auto !important;
  }
}

.emailInput button {
  max-width: 300px;
  /* height: 50px; */
  /* background-color: #0B80D4; */
}

.verifyInput {
  /* width: 15%; */
  min-width: 300px;
  max-width: 350px;;
  margin: auto !important;
}

.emailVerify button {
  /* width: 15%; */
  /* max-width: 300px; */
  /* height: 50px; */
  /* background-color: #0B80D4; */
}

button {
  /* remove default behavior */
  appearance:none;
  -webkit-appearance:none;

  /* usual styles */
  padding:10px;
  border:none;
  background-color:#0B80D4;
  color:#fff;
  /* font-weight:600; */
  /* border-radius:5px; */
  /* width:50%; */
  margin-top: 20px;
  font-size: 1.2em;
}

/* button:focus {
  outline-color: white;
  border-radius: 0;
} */
</style>
