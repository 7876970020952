<template>
    <v-container fill-height>
      <!-- <v-row 
      align="end"
      no-gutters>
      <v-col
          sm="12"
      >
        <h3>Nothing<br>Left<br>Behind</h3>
      </v-col>
      </v-row> -->
      <v-row 
      align="center"
      justify="center"
      no-gutters>
      <v-col
          sm="12"
      >
        <h2>Check and confirm</h2>
        <div>
          <p class="centralText">
            <b>You (</b>{{request['fullName']}}<b>) are changing your address from</b></p>
          <div v-for="(address, index) in request['oldAddress']" :key="index" class="address-holder mb-4">
            <p class="address mb-0">{{address}}</p>
            <p v-if="index < request['oldAddress'].length-1" class="centralText my-2">
              or
            </p>
          </div>
          <p class="centralText">
           <b>to</b>
          </p>
          <div class="address-holder">
            <p class="address">
              {{request['newAddress']}}
            </p>
          </div>
          <p class="centralText"><b>We'll asking the following organization to change your details:</b></p>
          <p v-for="o in request['organisations']" :key="o" class="centralText">
            {{o}}
          </p>
          <br>
          <p class="centralText">
            <b>A couple of final things:</b>
            <ul class="centralText-left">
              <li class="my-3">We will CC you into the requests</li>
              <li class="my-3">You'll get a confirmation with what's been requested</li>
              <li class="my-3">For requests to some organisations, we may generate a release form (using <a href="">this template</a>) and by using our
                service you accept that you're happy for this to go ahead</li>
              <li class="my-3">For your privacy, we don't store your details, but sadly this also means we can't assist if something goes wrong</li>
              <li class="my-3">Some organisations may ask you to confirm the change - usually a <i>"Hi! Yes, I confirm. Thank you!"</i> will do</li>
              <li class="my-3">Remember, we're still trying things out - so stuff might break</li>
              <li class="my-3">If you have any questions, get in touch with us at <a href="mailto:contact@nothingleftbehind.co.uk">contact@nothingleftbehind.co.uk</a></li>
            </ul>
          </p>
        </div>
      </v-col>
      </v-row>
      <v-row>
        <v-col
        sm="12">
        <div class="text-center">
            <v-btn
                color="secondary"
                @click="onClickButtonPrevious"
                class="mx-2"
              >
                Back
              </v-btn>
            <v-btn
                dark
                @click="onClickButtonNext"
                class="mx-2"
              >
                Send request
              </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";

export default {
  name: "ConfirmationStep",
  components: {
    // NavBar,
  },
  props: {
    request: Object,
    step: Number,
  },
  methods: {
    onClickButtonNext () {
      this.$emit('clicked', 1)
    },
    onClickButtonPrevious () {
      this.$emit('clicked', -1)
    },
    onGoToStep (value) {
      this.$emit('goToStep', value)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.centralText {
  /* max-width: 50%; */
  /* margin: auto; */
  text-align: center;
}

.centralText-left {
  max-width: 500px;
  margin: auto;
  text-align: left;
}

/* .nextBtn h4 {
  padding-top: 6px;
} */

ul {
  /* list-style: none; */
}

.address {
  white-space: pre-wrap;
}

.address-holder {
  max-width: 200px;
  margin: auto;
}
</style>
