<template>
    <EndOfFlow @goToHome="goToHome" :step="step"/>
</template>

<script>
// @ is an alias to /src
import EndOfFlow from "@/components/EndOfFlow.vue";
import store from '@/store';

export default {
  name: "EndCapture",
  components: {
    EndOfFlow,
  },
  data() {
    return {
      step: 0,
      request: {
        requestUUID: null,
        emailAddress: null,
        emailVerified: false,
        emailUUID: null,
        fullName: null,
        oldAddress: null,
        newAddress: null,
        cc: false,
        organisations: [],
      }
    }
  },
  methods: {
    goToHome () {
      store.dispatch('resetData');
      this.$router.push('/');
    },
    setRequestUUID (value) {
      this.request['requestUUID'] = value;
    },
    setEmailUUID (value) {
      this.request['emailUUID'] = value;
    },
    setEmailAddress (value) {
      this.request['emailAddress'] = value;
    },
    setEmailVerification () {
      this.request['emailVerified'] = true;
    },
    setFullName (value) {
      this.request['fullName'] = value;
    },
    setOldAddress (value) {
      this.request['oldAddress'] = value;
    },
    setNewAddress (value) {
      this.request['newAddress'] = value;
    },
    setCC (value) {
      this.request['cc'] = value;
    },
    setOrganisations (value) {
      this.request['organisations'] = value;
    },
  }
};
</script>
<style scoped>
.home {
  margin: 5vh auto;
  border-radius: 50px;
  height: 100%;
}
</style>
