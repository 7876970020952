<template>
    <EmailVerified @clicked="updateStep" @emailAddress="setEmailVerification" @emailUUID="setEmailUUID" v-if="this.request['emailVerified']" :step="step"/>
    <YourEmail @setEmailVerification="setEmailVerification" @emailAddress="setEmailAddress" @requestUUID="setRequestUUID" v-else :step="step"/>
</template>

<script>
// @ is an alias to /src
import YourEmail from "@/components/YourEmail.vue";
import EmailVerified from "@/components/EmailVerified.vue";
import store from '@/store'

export default {
  name: "YourEmailView",
  components: {
    YourEmail,
    EmailVerified,
  },
  data() {
    return {
      step: 0,
      request: {
        requestUUID: null,
        emailAddress: null,
        emailVerified: false,
        emailUUID: null,
        fullName: null,
        oldAddress: null,
        newAddress: null,
        cc: false,
        organisations: [],
      }
    }
  },
  methods: {
    updateStep (value) {
      this.step += value;
      this.$router.push('details');
    },
    customStep (value) {
      this.step = value;
    },
    setRequestUUID (value) {
      this.request['requestUUID'] = value;
      store.commit('setRequestUUID', value);
    },
    setEmailUUID (value) {
      this.request['emailUUID'] = value;
      store.commit('setEmailUUID', value);
    },
    setEmailAddress (value) {
      this.request['emailAddress'] = value;
      store.commit('setEmailAddress', value);
    },
    setEmailVerification () {
      this.request['emailVerified'] = true;
      store.commit('setEmailVerification', true);
    },
  }
};
</script>
<style scoped>
.home {
  margin: 5vh auto;
  border-radius: 50px;
  height: 100%;
}
</style>
