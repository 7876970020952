<template>
    <v-container fill-height>
      <!-- <v-row 
      align="end"
      no-gutters>
      <v-col
          sm="12"
      >
        <h3>Nothing<br>Left<br>Behind</h3>
      </v-col>
      </v-row> -->
      <v-row 
      align="center"
      justify="center"
      no-gutters>
        <v-col
          sm="12"
        >
        <div>
          <h2>Select organisations</h2>
          <div class="scrollHolder">
          <v-card
            flat
            color="background-color: rgba(255, 255, 255, 0.3)">
            <v-card-text>
              <v-container fluid>
                <v-row v-for="cat in categories" :key="cat" justify=center>
                  <p class="mb-0"><b>{{cat}}</b></p>
                  <v-col
                    sm="12"
                  >
                    <v-checkbox v-for="ic in individualCompanies[cat]" :key="ic"
                      color="primary"
                      hide-details
                      :id=ic
                      :name=ic
                      :value=ic
                      v-model="selectedOrgs"
                    >
                      <template v-slot:label>
                        <span class="mr-4">{{ic}}</span>
                        <v-icon
                          v-if="verified(ic)"
                          color="green darken-2"
                          title="Verified"
                        >
                         mdi-check-circle
                        </v-icon> 
                        <v-icon
                          v-if="idRequired(ic)"
                          color="#EAC0CE"
                          title="Requires ID"
                        >
                         mdi-card-account-details
                        </v-icon>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
          <!-- <div v-for="cat in categories" :key="cat" style="width: 60%; margin: auto;" class="categories">
            <h4 class="centralText">
              {{cat}}
            </h4>
            <p v-for="ic in individualCompanies[cat]" :key="ic" class="individualCompanies">
              <input type="checkbox" :id=ic :name=ic :value=ic v-model="selectedOrgs">
              <label :for=ic>{{ic}}</label><br>
              </p>
          </div> -->
          </div>
          </div>
        </v-col>
        </v-row>
         <v-row 
      align="center"
      justify="center"
      no-gutters>
      <v-col
          sm="12"
        >
        <div class="icon-legend">
          <v-icon
            color="green darken-2"
            title="Verified"
            class="ml-2"
          >
            mdi-check-circle
          </v-icon>
          <p class="ml-2 mr-5">Verified</p>
          <v-icon
            color="#EAC0CE"
            title="Requires ID"
            class="ml-2"
          >
            mdi-card-account-details
          </v-icon>
          <p class="ml-2 mr-5">Requires ID</p>
          <v-icon
            color="#5bb1fc"
            title="Requires ID"
            class="ml-2"
          >
            mdi-note-text
          </v-icon>
          <p class="ml-2 mr-5">Requires additional detail</p>
        </div>
      </v-col>
      </v-row>
         <v-row 
      align="center"
      justify="center"
      no-gutters>
      <v-col
          sm="12"
        >
        <p class="text-center mt-4"><b>{{numberOfSelected}}</b> selected</p>
        </v-col>
        <v-col
          sm="12"
        >
        <div class="text-center">
            <v-btn
                color="secondary"
                @click="onClickButtonPrevious"
                class="mx-2"
              >
                Back
              </v-btn>
            <v-btn
                dark
                @click="onClickButtonNext"
                class="mx-2"
              >
                Proceed
              </v-btn>
          </div>
          </v-col>
      </v-row>
    </v-container>
</template>

<script>
// import NavBar from "@/components/NavBar.vue";
import store from '@/store';

export default {
  name: "Organizations",
  components: {
    // NavBar,
  },
  props: {
    step: Number,
  },
  data() {
    return {
      categories: [
        'Groceries',
        'Government',
        'Retail',
        'Banks and Finance',
        'Travel',
      ],
      individualCompanies: {
        'Groceries': [
          'Aldi',
          'ASDA',
          'Co-op',
          'Lidl',
          'M&S',
          'Morrisons',
          'Sainsbury\'s',
          'Tesco',
          'Waitrose',
        ],
        'Government': [
          'DVLA',
          'HMRC',
        ],
        'Retail': [
          'Amazon',
          'ASOS',
          'Boots',
          'Currys PC World',
          'H&M',
          'John Lewis',
          'Next',
          'Odeon Cinemas',
          'ScrewFix',
          'TK Maxx',
        ],
        'Charities': [
          'Age UK',
          'Alzheimer\'s Association',
          'Alzheimer\'s Research UK',
          'Alzheimer\'s Society',
          'Barnardo\'s',
          'Battersea Dogs and Cats Home',
          'Bowel Cancer UK',
          'British Heart Foundation',
          'British Red Cross',
          'Cancer Research UK',
          'Children in Need',
          'Comic Relief',
          'Dementia UK',
          'Diabetes UK',
          'Dogs Trust',
          'Duke of Edinburgh Awards',
          'The Fairtrade Foundation',
          'Great Ormond Street Hospital',
          'Guide Dogs',
          'Help for Heroes',
          'Keep Britain Tidy',
          'London\'s Air Ambulance',
          'Macmillan Cancer Support',
          'Marie Curie',
          'Mind',
          'National Trust',
          'NSPCC',
          'Prostate Cancer UK',
          'RNLI Lifeboats',
          'Royal British Legion',
          'RSPB',
          'RSPCA',
          'Samaritans',
          'Salvation Army',
          'Save the Children',
          'St. John Ambulance',
          'Teenage Cancer Trust',
          'UNICEF',
          'Woodland Trust',
          'WWF',
        ],
        'Banks and Finance': [
          'American Express',
          'Bank of Scotland',
          'Barclays',
          'The Co-operative Bank',
          'HSBC',
          'Lloyds',
          'Metro Bank',
          'Monzo',
          'Natwest',
          'RBS',
          'Sainsbury\'s Bank',
          'Starling',
          'Teachers\' Pension',
          'Tesco Personal Finance',
          'Virgin Money',
        ],
        'Travel': [
          'Abellio ScotRail',
          'British Airways',
          'Caledonian Sleeper',
          'Chiltern Railways',
          'CrossCountry',
          'East Midlands Railway',
          'easyJet',
          'Emirates',
          'Eurostar',
          'Expedia',
          'Greater Anglia',
          'Great Western Railway',
          'Heathrow Express',
          'Jet2',
          'KLM',
          'London North Eastern Railway (LNER)',
          'Northern Trains',
          'RAC',
          'Ryanair',
          'Southeastern',
          'South Western Railway',
          'Tui',
          'TfL',
          'TransPennine Express',
          'Transport for Wales Rail',
          'West Midlands Trains',
        ]

      },
      selectedOrgs: store.getters.selectedOrganisations,
      orgDetails: {
        'Emirates': {
          'verified': true,
          'id': false,
        },
        'RAC': {
          'verified': true,
          'id': false,
        },
        'ScrewFix': {
          'verified': true,
          'id': false,
        },
        'Odeon Cinemas': {
          'verified': true,
          'id': false,
        },
        'Teachers\' Pension': {
          'verified': true,
          'id': false,
        },
        'KLM': {
          'verified': false,
          'id': true,
        },
        'Virgin Media': {
          'verified': false,
          'id': true,
        },
      }
    }
  },
  methods: {
    onClickButtonNext () {
      this.$emit('organisations', this.selectedOrgs)
      this.$emit('clicked', 1)
    },
    onClickButtonPrevious () {
      this.$emit('clicked', -1)
    },
    onGoToStep (value) {
      this.$emit('goToStep', value)
    },
    verified(org) {
      if(org in this.orgDetails) {
        if('verified' in this.orgDetails[org])
          return this.orgDetails[org]['verified'];
      }
      return false;
    },
    idRequired(org) {
      if(org in this.orgDetails) {
        if('id' in this.orgDetails[org])
          return this.orgDetails[org]['id'];
      }
      return false;
    }
  },
  computed: {
    numberOfSelected(){
      if (this.selectedOrgs) {
        return this.selectedOrgs.length;
      } else {
        return 0;
      }
    }
  },
  mounted() {
    if (process.env.VUE_TEST_ORG == 'dev') {
      this.categories.push(process.env.VUE_TEST_ORG)
      this.individualCompanies[process.env.VUE_TEST_ORG] = process.env.VUE_TEST_ORG;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  /* margin: 40px 0 25px; */
  text-align: center;
}

h3 {
  font-family: 'Abril Fatface', cursive !important;
  font-weight: normal !important;
}

h4 {
  margin-left: 0 !important;
  max-width: 100% !important;
}

.centralText {
  max-width: 50%;
  margin: auto;
}

.categories {
  text-align: left;
}

.individualCompanies {
  text-align: left;
}

.selected {
  background-color: red;
}

.scrollHolder {
  max-height: 60vh;
  overflow: scroll;
  overflow-x: hidden;
}

.icon-legend {
  display: inline-flex;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-radius: 4px;
}

.icon-legend p {
    margin-bottom: 0;
}
</style>
