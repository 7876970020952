<template>
    <Organizations @clicked="updateStep" @goToStep="customStep" @organisations="setOrganisations" :step="step"/>
</template>

<script>
// @ is an alias to /src
import Organizations from "@/components/Organizations.vue";
import store from '@/store';

export default {
  name: "OrganizationsSelection",
  components: {
    Organizations,
  },
  data() {
    return {
      step: 0,
      request: {
        requestUUID: null,
        emailAddress: null,
        emailVerified: false,
        emailUUID: null,
        fullName: null,
        oldAddress: null,
        newAddress: null,
        cc: false,
        organisations: [],
      }
    }
  },
  methods: {
    updateStep (value) {
      this.step += value;
      if(value == 1){
        this.$router.push('confirm');
      } else {
        this.$router.push('details')
      }
      
    },
    customStep (value) {
      this.step = value;
    },
    setOrganisations (value) {
      this.request['organisations'] = value;
      store.commit('setSelectedOrganisations', value)
    },
  }
};
</script>
<style scoped>
.home {
  margin: 5vh auto;
  border-radius: 50px;
  height: 100%;
}
</style>
